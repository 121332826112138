<template>
  <div>
    <el-row>
      <el-col :span="2">
        <!-- <div style="display: flex; align-items: center">
          <div style="font-size: 14px; color: #333333">学生上限人数：</div>
          <el-input-number
            style="width: 60%"
            v-loading="stuLoading"
            v-model="student_num"
            @change="handleDebouncedChange"
            :min="1"
            size="small"
            label="描述文字"
          ></el-input-number>
        </div> -->
        <div  style="display: flex; flex-direction: column">
          <el-radio v-model="radio" @input="changeType(1)" label="1">邀请名单</el-radio>
          <el-radio v-model="radio" @input="changeType(2)" label="2">免费名单</el-radio>
        </div>
        <!-- <form-create
          v-model="where"
          :components="[
            {
              name: 'form-item',
              props: { prop: 'keyword', label: '学生名称' },
            },
          ]"
          inline
          :labelWidth="'70px'"
        >
          <el-form-item class="operate" slot="after">
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset(where)"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset((where = { status: null }))"
              >重置</el-button
            >
          </el-form-item>
        </form-create> -->
      </el-col>
      <!-- <el-col  :span="18" v-if="courseInfo.is_free + '' !== 0 + ''"> -->
      <el-col :span="22">
        <!-- <div v-if="courseInfo.is_free + '' !== 0 + ''">
          添加学生白名单：<el-button
            :disabled="!$isPowers('courseStudyCreate')"
            size="small"
            type="primary"
            @click="
              $refs.dialog.visibled(
                (dialogData = { title: '添加白名单', data: {} }) && true
              )
            "
            >添加白名单</el-button
          >
        </div>
        <div v-else>&nbsp;</div> -->
        <student-save
          ref="studentSave"
          :course_id="course_id"
          v-model="dialogData.data"
          @success="success"
        >
          <span slot="after"> </span>
        </student-save>
      </el-col>
    </el-row>

    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      "
    >
      <!-- <el-button-group>
        <el-button
          size="small"
          :type="tableType == 1 ? 'primary' : ''"
          @click="changeType(1)"
          >基准名单待添加列表</el-button
        >
        <el-button
          size="small"
          :type="tableType == 2 ? 'primary' : ''"
          @click="changeType(2)"
          >费用白名单待添加列表</el-button
        >
      </el-button-group>
      <div>
        <div v-if="nloginsList.length > 0" style="font-size: 14px; color: red">
          表格内以下学生未注册：{{ nloginsList.join("、") }},请确认后添加！
        </div>
        <div v-if="dupsList.length > 0" style="font-size: 14px; color: red">
          表格内以下学生重名：{{ dupsList.join("、") }},请确认后添加！
        </div>
      </div> -->
      
      <el-button
        type="primary"
        size="small"
        @click="$refs.studentSave.handleCommand('save',tableType)"
        >确认添加</el-button
      >
      <div>
        <div v-if="nloginsList.length > 0" style="font-size: 14px; color: red">
          表格内以下学生未注册：{{ nloginsList.join("、") }},请确认后添加！
        </div>
        <div v-if="dupsList.length > 0" style="font-size: 14px; color: red">
          表格内以下学生重名：{{ dupsList.join("、") }},请确认后添加！
        </div>
      </div>
      <div style="display: flex; align-items: center;justify-content: space-between;">
          <div style="font-size: 14px; color: #333333">学生上限人数：</div>
          <el-input-number
            style="width: 60%"
            v-loading="stuLoading"
            v-model="student_num"
            @change="handleDebouncedChange"
            :min="1"
            size="small"
            label="描述文字"
          ></el-input-number>
        </div>
    </div>

    <!-- <el-table
      style="margin-top: 20px"
      :data="tableType == 1 ? basisTable : whiteTable"
      :row-style="{ height: '0' }"
      :header-cell-style="{
        padding: '8px',
        background: '#F9F9F9',
      }"
      min-height="200"
    >
      <el-table-column
        type="index"
        label="序号"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        property="nickname"
        label="学生昵称"
        align="center"
      ></el-table-column>
      <el-table-column
        property="realname"
        label="真实姓名"
        align="center"
      ></el-table-column>

      <el-table-column property="phone" label="手机号" align="center">
      </el-table-column>
      <el-table-column property="school_name" label="学校名称" align="center">
      </el-table-column>
      <el-table-column property="remark" label="操作" align="center">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            width="160"
            :ref="`popover-${scope.$index}`"
          >
            <p>是否将该名学生从列表删除？</p>
            <div style="text-align: right; margin: 0">
              <el-button
                size="mini"
                type="text"
                @click="scope._self.$refs[`popover-${scope.$index}`].doClose()"
                >取消</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="
                  confirmBtn(
                    scope.$index,
                    tableType == 1 ? basisTable : whiteTable
                  ),
                    scope._self.$refs[`popover-${scope.$index}`].doClose()
                "
                >确定</el-button
              >
            </div>
            <el-button type="text" size="small" slot="reference"
              >删除</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
    </el-table> -->

    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
      "
    >
      <div style="font-size: 18px; color: #333333">学生列表：</div>
      <!-- <div>
        <el-button size="small" type="primary" @click="saveStu(tableType)"
          >保存</el-button
        >
      </div> -->
    </div>
    <com-table
      :columns="[
        { type: 'index', label: '序号', width: '50', align: 'center' },
        { prop: 'nickname', label: '学生昵称', width: '90', align: 'center' },
        { prop: 'realname', label: '真实姓名', width: '90', align: 'center' },
        { prop: 'phone', label: '手机号', width: '150', align: 'center' },
        { prop: 'school_name', label: '学校', width: '150', align: 'center' },
        { prop: 'is_base_s', label: '邀请名单', width: '90', align: 'center' },
        {
          prop: 's_status_s',
          label: '邀请名单学生同意',
          slot: 's_status_s',

          width: '150',
          align: 'center',
        },
        { prop: 'is_apply_s', label: '主动报名', width: '90', align: 'center' },
        { prop: 't_status_s', label: '老师批准', width: '90', align: 'center' },
        {
          prop: 'is_white_s',
          label: '免费名单',
          width: '150',
          align: 'center',
        },
        {
          prop: 'w_status_s',
          label: '免费名单学生同意',
          slot: 'w_status_s',

          width: '150',
          align: 'center',
        },
        { prop: 'pay_date', label: '实缴日期', width: '120', align: 'center' },
        { prop: 'price', label: '实付金额', width: '90', align: 'center' },
        // {prop:'typeStr',label:'模式',width:'160'},
        { prop: 'status_s', label: '状态', align: 'center' },
        {
          prop: 'remark',
          label: '备注',
          align: 'center',
          slot: 'remark',
          width: '200',
        },
        // { prop: 'create_time', label: '创建时间', align: 'center' },
        // { prop: 'buy_price', label: '付费金额', align: 'center' },
        {
          fixed: 'right',
          label: '操作',
          width: '340',
          slot: 'action',
          align: 'center',
        },
      ]"
      :data="{ url: '/xapi/course.course/studentAllList' }"
      :page-size="50"
      :where="where"
      ref="table"
    >
      <template slot="remark" slot-scope="{ row }">
        <el-input
          v-model="row.remark"
          placeholder="请输入备注"
          @change="changeExplain(row, row.bid, row.remark)"
          style="width: 100%"
          class="inputClass"
        ></el-input
      ></template>
      <template slot="s_status_s" slot-scope="{ row }">
        <div>{{ row.s_status_s }}
          <el-button
            type="primary"
            v-if="row.s_status_s=='未反馈'"
            style="margin-left: 4px;"
            @click="recall(row,'CourseBaseUser')"
            size="mini"
            >撤回邀请</el-button
          ></div>
        
        </template>
      <template slot="w_status_s" slot-scope="{ row }">
        <div>{{ row.w_status_s }}
          <el-button
            type="primary"
            v-if="row.w_status_s=='未反馈'"
            style="margin-left: 4px;"
            @click="recall(row,'courseWhite')"
            size="mini"
            >撤回邀请</el-button
          ></div>
        
        </template>
      <template slot="action" slot-scope="{ row }">
        <el-button-group>
          <!-- <el-button type="primary" v-if="row.status!=0 && row.status!=2" :disabled="!$isPowers('courseDrop')" @click="$refs.txDialog.visibled((currentData = {...row}) && true)" size="mini">退课</el-button> -->
          <el-button
            type="primary"
            v-if="row.status==1"
            :disabled="!$isPowers('courseDrop')"
            @click="quitCourse(row)"
            size="mini"
            >退学</el-button
          >
          <el-button
            type="primary"
            v-if="row.status==1"
            :disabled="!$isPowers('courseTransfer')"
            @click="$refs.zkDialog.visibled((currentData = { ...row }) && true)"
            size="mini"
            >转班</el-button
          >
          <el-button
            v-if="row.w_status == 1"
            @click="
              $handleConfirm(
                '您确定将学生「' + row.nickname + '」踢出白名单吗?',
                '/xapi/course.course/studentDel',
                { ...$route.params, user_id: row.id },
                () => {
                  $refs.table.reload();
                },
                { title: '踢出白名单' }
              )
            "
            type="primary"
            size="mini"
            >踢出白名单</el-button
          >

          <!-- <el-button type="primary" :disabled="!$isPowers('courseStudyEdit') || row.type===''" size="mini" @click="$refs.dialog.visibled((dialogData = {title:'学生编辑',data:{user_id:row.id,...row}}) && true)">编辑</el-button> -->
          <el-button
            type="primary"
            v-if="row.status == 1 || row.type == ''"
            :disabled="!$isPowers('courseStudySpeed')"
            size="mini"
            @click="
              $handleRoute(
                { study_user_id: row.id, course_id: course_id },
                'courseCourseStudySpeed'
              )
            "
            >查看学习进度</el-button
          >
          <!-- <el-button
            type="primary"
            v-if="row.status == 2"
            :disabled="!$isPowers('courseStudyDel')"
            size="mini"
            @click="
              $handleConfirm(
                '你确定删除此学生吗？',
                '/xapi/course.course/studentDel',
                { ...$route.params, user_id: row.id },
                () => {
                  $refs.table.reload();
                }
              )
            "
            >删除</el-button> -->
          <el-button
            v-if="isTeach && courseInfo.learn_mode == 2"
            type="primary"
            :disabled="!$isPowers('courseStudyZYue')"
            @click="
              $refs.ZYueDialog.visibled((currentData = { ...row }) && true)
            "
            size="mini"
          >
            <!-- 章节/条目直阅 -->
            学习内容授权
          </el-button>
        </el-button-group>
      </template>
    </com-table>
    <diy-dialog ref="dialog" :title="dialogData.title" width="550px" center>
      <student-save
        ref="studentSave"
        :course_id="course_id"
        v-model="dialogData.data"
        @success="
          $refs.table.reload();
          $refs.dialog.visibled(false);
        "
      >
        <span slot="after"> </span>
      </student-save>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.dialog.visibled(false)">取 消</el-button>
        <el-button
          type="primary"
          @click="$refs.studentSave.handleCommand('save')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="txDialog" :title="'退课'" width="650px" center>
      <form-create
        ref="txForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'leave_type',
              label: '退课方式',
              required: true,
              type: 'radio',
              defaultValue: 1,
              data: {
                url: '/xapi/course.course/studentOptions/type/leave_type',
              },
              dataMethod(data) {
                data = data.map((d) => {
                  d.disabled =
                    (!Number(currentData.price) > 0 ||
                      currentData.price == null) &&
                    ['2', '3'].indexOf(d.value) > -1;
                  return d;
                });
              },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'refund_price',
              label: '退款金额',
              required: true,
              disabled: false,
              value: 0,
              type: 'number',
              max: Number(currentData.price),
            },
            condition: '{{leave_type}}==2',
            hidden: isTeach === false,
            slots: { after: '元' },
          },
          {
            name: 'form-item',
            props: {
              prop: 'refund_price',
              label: '退款金额',
              disabled: true,
              value: currentData.price,
              type: 'number',
              max: parseFloat(currentData.price),
            },
            condition: '{{leave_type}}==3',
            hidden: isTeach === false,
            slots: { after: '元' },
          },
          {
            name: 'form-item',
            props: {
              prop: 'leave_reason',
              label: '退课理由',
              required: true,
              type: 'textarea',
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'contact_mobile',
              label: '联系方式',
              required: true,
            },
            hidden: isTeach === true,
          },
        ]"
      >
      </form-create>
      <!-- <form-create ref="txForm" style="text-align: left;" label-width="100px" :components="[
                {name:'form-item',props:{prop:'leave_type',label:'退学方式',required:true,type:'radio',defaultValue:1,
                data:[
              {label:'男',value:'1'},
              {label:'女',value:'2'},
              {label:'保密',value:'3'},
            ]}},
                {name:'form-item',props:{prop:'refund_price',label:'退款金额',required:true,disabled:false,value:0,type:'number',max: Number(currentData.price) },condition:'{{leave_type}}==2',hidden:isTeach===false,slots:{after:'元'}},
                {name:'form-item',props:{prop:'refund_price',label:'退款金额',disabled:true,value: currentData.price,type:'number',max:parseFloat(currentData.price)},condition:'{{leave_type}}==3',hidden:isTeach===false,slots:{after:'元'}},
                {name:'form-item',props:{prop:'leave_reason',label:'退学理由',required:true,type:'textarea'}},
                {name:'form-item',props:{prop:'contact_mobile',label:'联系方式',required:true},hidden:isTeach===true},
      ]"
      > </form-create> -->
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.txDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('txSave')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="zkDialog" :title="'转课'" width="650px" center>
      <form-create
        ref="zkForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'transfer_course_id',
              label: '选择课程',
              required: true,
              type: 'select',
              data: {
                url: '/xapi/course.course/myList',
                labelOptions: { value: 'id', label: 'course_name' },
                params: { course_id, is_plan: 0, is_up: 1 },
              },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'transfer_reason',
              label: '转课理由',
              required: true,
              type: 'textarea',
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'contact_mobile',
              label: '联系方式',
              required: true,
            },
            hidden: isTeach === true,
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.zkDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('zkSave')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="ZYueDialog" :title="'学习内容授权'" width="650px" center>
      <form-create
        ref="txForm"
        style="text-align: left"
        label-width="120px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'unit_list_ids',
              label: '选择章节/条目',
              multiple: true,
              type: 'data',
              showKey: 'name',
              required: true,
              columnKey: 'key',
              dialog: {
                width: '650px',
                url: '/xapi/course.course/unitList',
                params: { course_id },
                table: {
                  treeProps: {
                    hasChildren: 'hasChildren',
                    children: 'children',
                    isCheck: false,
                  },
                },
              },
              whereColumns: [
                {
                  name: 'form-item',
                  props: {
                    prop: 'keyword',
                    label: '关键字',
                    placeholder: '请输入章节、条目名称',
                    style: { width: '350px' },
                  },
                },
              ],
              columns: [
                // {
                //   prop: 'key',
                //   label: '编号',
                //   width: '120',
                //   align: 'center',
                //   fixed: 'left',
                // },
                { prop: 'title', label: '章节/条目名称', minWidth: '220' },
                {
                  prop: 'create_date',
                  label: '创建时间',
                  width: '160',
                  align: 'left',
                },
              ],
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'reason',
              label: '备注',
              // required: true,
              type: 'textarea',
            },
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.ZYueDialog.visibled(false)">取 消</el-button>
        <el-button
          type="primary"
          @click="handleCommand('zyueSave', currentData)"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
import StudentSave from "@/views/course/course/studentSave";
export default {
  name: "teachingCourseStudy",
  components: { StudentSave, DiyDialog },
  props: ["course_id"],
  data() {
    return {
      dialogData: { title: "", data: {} },
      where: { status: null },
      courseInfo: {},
      currentData: {},
      isTeach: false,
      isStudent: false,
      stuLoading: false,
      student_num: 0,

      debounceTimer: null,
      debounceDelay: 500, // 设置防抖延迟时间，单位为毫秒
      teachLoading: false,
      tableType: 1, //1 基准 2费用白名单
      basisTable: [], //基准名单
      whiteTable: [], //基准名单
      nloginsList: [], //未注册名单
      dupsList: [], //重名名单
      visible: false,
      radio:'1'
    };
  },
  beforeDestroy() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer); // 组件销毁时清除定时器
    }
  },
  methods: {
    routeUpdate() {
      this.getDetail();
    },
    // routeUpdate() {
    //   console.log(this.course_id, "course_id");
    //   // return
    //   if (this.course_id > 0) {

    //     this.$http
    //       // .post("/xapi/course.course/detail", { id: this.course_id })
    //       .post("/xapi/course.course/detail_simplen", { id: this.course_id })
    //       .then(({ data, isOnlyField }) => {
    //         data.is_free =
    //           data.is_free === undefined
    //             ? data.price > 0
    //               ? 1
    //               : 0
    //             : data.is_free;
    //         this.courseInfo = data;
    //         this.isTeach = data.isTeach === true;
    //         this.isStudent = data.isStudent === true;
    //       });
    //   }
    // },
    changeType(e) {
      console.log(e,"eeeeeee")
      this.tableType = e;
    },
    // 取消
    cancelBtn() {
      this.visible = false;
      console.log("quxiao");
    },
    // 确定
    confirmBtn(index, table) {
      console.log(index, "indexxxxxxxx");
      table.splice(index, 1);
    },
    // 确认添加
    success(data) {
      console.log(data, "传过来的数据");
      // if (this.tableType == 1) {
      //   this.basisTable = data.data.list.data;
      // } else {
      //   this.whiteTable = data.data.list.data;
      // }
      // 未注册
      this.nloginsList = data.data.nlogins;
      console.log(this.nloginsList, "this.nloginsList");
      // 重名
      this.dupsList = data.data.dups;
      // this.$refs.table.reload();
      // this.$refs.dialog.visibled(false);
      this.$refs.table.reload();

    },
    // 删除学生
    deleteStu(index, row) {
      console.log(index, "indexxxxxxxx");
      row.splice(index, 1);
    },
    //编辑学生
    editStu() {
      console.log("编辑");
    },
    //保存学生
    saveStu(e) {
      console.log(e, "保存学生");
      // 保存基准
      if (e == 1) {
        let data = {
          course_id: this.course_id,
          user_ids: this.basisTable.map((v) => v.id),
        };
        this.$http
          .post("/xapi/course.course/setCourseBaseUser", data)
          .then((res) => {
            this.basisTable = [];
            this.$message.success(res.msg);
            this.$refs.table.reload();
          });
      } else {
        // 保存白名单
        let data = {
          course_id:  this.course_id,
          user_id: this.whiteTable.map((v) => v.id),
        };
        console.log(data, "dataaaaaaa");
        this.$http.post("/xapi/course.course/studentSave", data).then((res) => {
          this.whiteTable = [];
          this.$message.success(res.msg);
            this.$refs.table.reload();
            this.$refs.table.reload();
        });
      }
    },
    // 请输入备注
    changeExplain(row, bid, e) {
      console.log(row, "row");
      console.log(bid, "iddddddddd");
      console.log(e, "备注");
      let data = {
        field: "remark",
        value: e,
        id: bid,
      };
      this.$http.post("/xapi/user/updateBaseUser", data).then((res) => {
        console.log(res,"resssssss")
        this.$message.success("保存成功");
        this.$refs.table.reload();
      });
      // let a = this.table[index];
      // console.log(a, "aaaaaaaaaaa");
    },
    // 更改学生上限
    handleChange() {
      console.log("防抖后的值：", this.student_num);
      this.$http
        .post("/xapi/course.course/updateStudentNum", {
          id: this.course_id,
          student_num: this.student_num,
        })
        .then((res) => {
          this.$message.success(res.msg);
          this.getDetail();
          console.log(res, "resssssssss");
        });
    },
    getDetail() {
      this.stuLoading=true
      if (this.course_id > 0) {
        this.$http
          // .post("/xapi/course.course/detail", { id: this.course_id })
          .post("/xapi/course.course/detail_simplen", { id: this.course_id })
          .then(({ data, isOnlyField }) => {
            this.stuLoading=false

            this.courseInfo = data;
            this.student_num = this.courseInfo.student_num;
          });
      }
    },
    // 数量改变
    handleDebouncedChange(e) {
      console.log(e, "eee");
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer); // 清除上一次的定时器
      }
      this.debounceTimer = setTimeout(() => {
        this.handleChange();
      }, this.debounceDelay);
    },
    handleCommandZyueSave() {
      return this.$refs.txForm
        ? this.$refs.txForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentZyue", {
                  course_id: this.course_id,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "操作成功！", "success", () => {
                    this.$refs.ZYueDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    handleCommandTxSave() {
      return this.$refs.txForm
        ? this.$refs.txForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentTx", {
                  course_id: this.course_id,
                  refund_price: this.$refs.txForm.form.refund_price,
                  price: this.currentData.price,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "退课成功！", "success", () => {
                    this.$refs.table.reload();
                    this.$refs.txDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    handleCommandZkSave() {
      return this.$refs.zkForm
        ? this.$refs.zkForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentZk", {
                  course_id: this.course_id,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "转课成功！", "success", () => {
                    this.$refs.table.reload();
                    this.$refs.zkDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    //退课
    quitCourse(e) {
      console.log(e, "eeeeeeee");
      if (e.is_change == 1) {
        this.$confirm("该学生退课是否涉及退费，请您仔细确认核实。", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            console.log("确定");
            this.$refs.txDialog.visibled((this.currentData = { ...e }) && true);
          })
          .catch(() => {
            console.log("取消");
          });
      } else {
        this.$refs.txDialog.visibled((this.currentData = { ...e }) && true);
      }
    },
    // 撤回邀请
    recall(e,type){
      console.log(e,"eeeeee")
      console.log(type,"type")
      this.$confirm('是否撤销当前邀请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

        this.$http
        .post("/xapi/course.course/cancelApply", {
          user_id: e.id,
          course_id: this.course_id,
          type: type,
        })
        .then((res) => {
          this.$message({
            type: 'success',
            message: '撤销成功!'
          });
          this.$refs.table.reload();
        });
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消撤销'
          });          
        });
    }
  },
};
</script>

<style scoped>
</style>