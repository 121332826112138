<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div style="width: 30%">
        <span style="font-size: 14px; margin-right: 4px">选择用户:</span>
        <el-select
          style="width: 80%"
          v-loading="stuLoading"
          v-model="user_ids"
          filterable
          clearable
          remote
          multiple
          reserve-keyword
          :remote-method="getStulist"
          @change="chooseStu"
          placeholder="请选择"
        >
          <el-option
            v-for="item in stuList"
            :key="item.id"
            :label="item.phone_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <form-create
        style="width: 30%"
        ref="form"
        v-model="dataForm"
        :label-width="'100px'"
        :components="components"
        @update="$emit('change', dataForm)"
      >
        <template slot="after">
          <slot name="after">
            <div style="text-align: center">
              <el-button type="primary" @click="handleCommand('save')"
                >确 定</el-button
              >
            </div>
          </slot>
        </template>
      </form-create>
      <div style="flex: 1; display: flex; margin-top: 4px;margin-left: 40px;">
        <div style="font-size: 14px">导入文件：</div>
        <div style="margin-top: -6px">
          <el-upload
            ref="upload"
            action="#"
            :before-upload="beforeUpload"
            :on-error="onError"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div class="filename">{{ filename }}</div>
          <div style="font-size: 12px">
            <p>
              点击下载
              <el-button
                type="text"
                @click="
                  $tool.fileResponse(
                    '',
                    { file: 'import/white_user.xlsx' },
                    '学生名单.xlsx'
                  )
                "
                size="small"
                >学生名单模板</el-button
              >，根据导入信息获取学生名单
            </p>
            <p style="color: red">
              注意：导入的学生信息必须已经注册。用户、团队、导入文件必选其一
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <el-button
      type="primary"
      @click="
        $tool.fileResponse(
          '',
          { file: 'import/white_user.xlsx' },
          '白名单学生.xlsx'
        )
      "
      >下载</el-button
    > -->

    <!-- <el-button type="primary" @click="handleCommand('save')">确 定</el-button> -->
  </div>
</template>

<script>
// import * as XLSX from 'xlsx'; // 导入xlsx库
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    course_id() {
      return {};
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dataForm: this.value,
      courseInfo: {},
      stuLoading: false,
      fileList: [],
      action: "",
      filename: "",
      file: "",
      stuList: [],
      value1: "",
      user_ids: [],
      list:[],
    };
  },
  computed: {
    components() {
      var _this = this;
      return [
        {
          name: "el-row",
          components: [
            // {
            //   name: "el-col",
            //   props: { span: 11 },
            //   components: [
            //     {
            //       name: "form-item",
            //       props: {
            //         prop: "user_ids",
            //         label: "选择用户",
            //         type: "select",
            //         reserveKeyword: false,
            //         multiple: true,
            //         disabled: _this.dataForm.id > 0,
            //         data: {
            //           url: "/xapi/user/list",
            //           labelOptions: { value: "id", label: "showname" },
            //         },
            //       },
            //     },
            //   ],
            // },
            {
              name: "el-col",
              props: { span: 24 },
              components: [
                {
                  name: "form-item",
                  props: {
                    prop: "group_id",
                    label: "团队拷贝",
                    type: "select",
                    multiple: true,
                    data: {
                      url: "/xapi/user.group/list",
                      params: { type: 1 },
                      labelOptions: { value: "id", label: "name" },
                    },
                  },
                  hidden: _this.dataForm.id > 0,
                },
              ],
            },
            // {
            //   name: "el-col",
            //   props: { span: 8 },
            //   components: [
            //     {
            //       name: "form-item",
            //       props: {
            //         prop: "file",
            //         label: "导入文件:",
            //         type: "upload",
            //         limit: 1,
            //         "list-type": "text",
            //         "show-file-list": true,
            //         accept: ".xlsx",
            //         disabledUpload: true,
            //         notice: '<a href="https://www.baidu.com">导入模板</a>',
            //       },
            //       slots: {
            //         tip: [
            //           {
            //             name: "span",
            //             domProps: { innerHTML: "点击下载" },
            //           },
            //           {
            //             name: "el-link",
            //             on: {
            //               click: () => {
            //                 _this.$tool.fileResponse(
            //                   "",
            //                   { file: "import/white_user.xlsx" },
            //                   "白名单学生.xlsx"
            //                 );
            //               },
            //             },
            //             domProps: {
            //               innerHTML:
            //                 "<span  style='color:var(--color-danger);'>白名单导入模板</span> ",
            //             },
            //           },
            //           {
            //             name: "span",
            //             domProps: {
            //               innerHTML:
            //                 "，根据导入信息获取白名单学生<p style='color:var(--color-danger);'>注意：导入的学生信息必须已经注册。用户、团队、导入文件必选其一</p>",
            //             },
            //           },
            //         ],
            //       },
            //       hidden: _this.dataForm.id > 0,
            //     },
            //   ],
            // },
          ],
        },

        // {name:'form-item',props:{prop:'type',required:true,defaultValue:0,label:'模式',type:'radio'},callback(c){
        //     if( _this.courseInfo.learn_mode === 2){
        //       c.props.data = [ { value: 0,  label: '默认' }, { value: 2, label: '强制模式' } ];
        //     }else{
        //       c.props.data = [ { value: 0,  label: '默认' }, { value: 1, label: '引导模式' }, { value: 2, label: '强制模式' } ];
        //     }
        //   }},
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        if (this.$tool.obj_equal(v, this.dataForm) === false) {
          this.dataForm = v;
        }
      },
    },
    course_id() {
      this.getDetail();
    },
  },
  created() {
    // this.action = location.baseURL + "xapi/user/getAlterUserList";
    this.getStulist();
    this.action = location.baseURL + "/xapi/com.upload/upload";
    console.log(this.action, "this.action");
  },
  mounted() {
    // this.list = this.states.map(item => {
    //     return { value: `value:${item}`, label: `label:${item}` };
    //   });
  },
  methods: {
    chooseStu() {
      console.log(this.user_ids, "1111");
    },
    // 服务器搜索
    // remoteMethod(query) {
    //     if (query !== '') {
    //       this.loading = true;
    //       setTimeout(() => {
    //         this.loading = false;
    //         this.stuList = this.list.filter(item => {
    //           return item.label.toLowerCase()
    //             .indexOf(query.toLowerCase()) > -1;
    //         });
    //       }, 200);
    //     } else {
    //       this.stuList = [];
    //     }
    //   },
    // 获取学生列表
    getStulist(key) {
      this.stuLoading = true;
      this.$http
        .post("/xapi/user/list", { pageSize: 100,keyword:key,type:1 })
        .then(({ data }) => {
          this.stuLoading = false;

          console.log(data, "获取学生列表");
          this.stuList = data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCommandSave(e) {
      console.log(e,"名单类型")//1邀请名单2免费名单
      // return
      this.$refs.form.validate((formData, valid) => {
        if (valid) {
          // console.log(formData,"formData")
          // return
          //免费白名单
          if(e==2){
            formData.file = this.file;
            formData.user_ids = this.user_ids;
            console.log(formData, "formData");
            console.log(this.file, "this.file");
            console.log(this.$route.params, "...this.$route.params");
            var _formData = new FormData(),
              data = { ...formData, ...this.$route.params };
            for (const dataKey in data) {
              if (data[dataKey] !== null && data[dataKey] !== undefined)
                _formData.append(dataKey, data[dataKey]);
            }
            console.log(_formData, "_formData_formData");
            this.$http
              // .post("/xapi/user/getAlterUserList", _formData)
              .post("/xapi/course.course/studentWhiteSave", _formData)
              .then((data) => {
                console.log(data, "dataaaaaaa");
                this.$message.success(data.msg)
                this.$emit("success", data);
                // this.$handleMessage(data.msg || "操作成功！", "success", () => {
                // });
              });
          }else{
            // 邀请名单
            formData.file = this.file;
            formData.user_ids = this.user_ids;
            console.log(formData, "formData");
            console.log(this.file, "this.file");
            console.log(this.$route.params, "...this.$route.params");
            var _formData = new FormData(),
              data = { ...formData, ...this.$route.params };
            for (const dataKey in data) {
              if (data[dataKey] !== null && data[dataKey] !== undefined)
                _formData.append(dataKey, data[dataKey]);
            }
            console.log(_formData, "_formData_formData");
            console.log(formData, "formDataformData");
            this.$http
              // .post("/xapi/user/getAlterUserList", _formData)
              .post("/xapi/course.course/studentCourseBaseSave", _formData)
              .then((data) => {
                console.log(data, "dataaaaaaa");
                this.user_ids=[]
                this.$message.success(data.msg)
                this.$emit("success", data);
                // this.$handleMessage(data.msg || "操作成功！", "success", () => {
                // });
              });
          }

          return;
          this.$http
            .post("/xapi/course.course/studentSave", _formData)
            .then((data) => {
              this.$handleMessage(data.msg || "操作成功！", "success", () => {
                this.$emit("success");
              });
            });
        }
      });
    },
    getDetail() {
      this.courseInfo = {};
      if (this.course_id > 0) {
        this.loading = true;
        this.$http
          .post("/xapi/course.course/detail", { id: this.course_id })
          .then(({ data }) => {
            this.courseInfo = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    beforeUpload(file) {
      const isExcel = /\.(xlsx|xls|csv)$/.test(file.name);
      if (!isExcel) {
        this.$message.error("只能上传.xlsx、.xls、.csv 文件!");
        return false;
      }
      this.file = file;
      console.log(file, "fileeeeee");
      this.filename = file.name;
      console.log(this.filename, "this.filename");
      return false;
    },
    onSuccess(response, file, fileList) {
      // 文件上传成功的处理逻辑
      console.log("File uploaded successfully:", response);
      console.log("File uploaded successfully:", file.raw);
      console.log("File uploaded successfully:", fileList);
    },
    onError(err, file, fileList) {
      // 文件上传失败的处理逻辑
      console.error("Error while uploading file:", err);
    },
    // change(){
    //   console.log("555555555555555");
    // },
  },
};
</script>

<style scoped>
</style>